const Initial_State = {
    User_data: []
}

export const todoreducer = (state = Initial_State, action) => {
    switch (action.type) {
        case "ADD_DATA":
            return {
                ...state,
                User_data: [...state.User_data, action.payload]
            }

        case "RMV_DATA":
            const delData = state.User_data.filter((elem, k) => k !== action.payload)
            return {
                ...state,
                User_data: delData

            }

        case "UPDATE_DATA":
            const updateData = state.User_data.map((elem , k)=> k == action.d ? action.payload : elem)
            return {
                ...state,
                User_data: updateData 

            }
        default:
            return state

    }
}