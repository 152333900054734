import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Remove , Update } from '../../Redux/actions/action';


const Todolist = () => {

    const dispatch = useDispatch();

    const { User_data } = useSelector((state) => state.todoreducer);
    console.warn(User_data);

    const [showeye, setShoweye] = useState(false);
    const [showEyeValue, setShowEyeValue] = useState('')

    const [show, setShow] = useState(false);
    const [update, setUpdate] = useState('');
    const [ind, setind] = useState('');




    const handleShow = (element) => {
        setShow(true)
        setUpdate(element)
    }
    const handleClose = () => setShow(false);

    // Delete Function
    const remove = (id) => {
        dispatch(Remove(id))
    }

    // Update Function
    const usertask_update = ()=>{
        dispatch(Update(update , ind))
        handleClose()

    }

    
    return (
        <>
            {
                User_data.map((element, index) => {
                    return (
                        <>
                            <div key={index} className="todo_data col-lg-5 mx-auto mt-2" >
                                <div className="todo_container" >
                                    <li >{element}</li>
                                    <div className="icons col-lg-3" >
                                        <EditIcon onClick={() => {
                                            handleShow(element)
                                            setind(index)
                                        }
                                        } />
                                        <DeleteIcon onClick={() => remove(index)} />
                                        <RemoveRedEyeIcon onClick={() => {
                                            setShoweye(true)
                                            setShowEyeValue(element)
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }
            {/* Read Modal */}
            <Modal show={showeye}>

                <h1 className='eye-title'>{showEyeValue}</h1>

                <Modal.Footer>

                    <Button variant="secondary" onClick={() => setShoweye(false)}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

            {/* Update Modal */}

            <Modal show={show} onHide={handleClose}>
                <h1 className='update-title'>Update Your Task</h1>
                <Modal.Body>
                    <div className="todo col-lg-10 mx-auto d-flex justify-content-between align-items-center">
                        <input type="text" name='task' value={update} 
                            onChange={(e) => setUpdate(e.target.value)} className='form-control' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={()=>usertask_update()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default Todolist