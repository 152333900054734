import React from 'react'
import Home from './Components/Home'
import Navbar from './Components/Navbar'
import './Style/Style.css';
import 'bootstrap/dist/css/bootstrap.min.css'

const ReduxTodo = () => {
    return (
        <>
            <Navbar />
            <div className='back'>

            <Home />
            </div>
        </>
    )
}

export default ReduxTodo