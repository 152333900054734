import { Button } from '@mui/material'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import Todolist from './Todolist';
import { useDispatch } from 'react-redux';
import { Add } from '../../Redux/actions/action';

const Home = () => {

  const [data, setData] = useState("");
  console.warn(data);

  const dispatch = useDispatch();

  const addData = ()=>{
    dispatch(Add(data))

    setData("")
  }




  return (
    <div className="container">
      <section className="mt-3 text-center">
        <h3>Enter Your Task</h3>

        <div className="todo col-lg-5 mx-auto d-flex justify-content-between align-items-center">
          <input type="text" name='task' value={data} onChange={(e) => setData(e.target.value)} className='form-control' />
          <Button variant="contained"
          onClick={()=>addData()}
          className='mx-2' style={{ background: 'var(--themeRed)' }}>
            <AddIcon />
          </Button>
        </div>

        <Todolist />

      </section>
    </div>
  )
}

export default Home